import {UPDATE_SERVIERTIME,UPDATE_VIDEO,UPDATE_TOKEN, UPDATE_USER,UPDATE_NOTIFICATION, UPDATE_SEARCHINFO,UPDATE_SEARCH_CLICk,UPDATE_SEARCH,UPDATE_PAYLIST, UPDATE_MONNEYLIST, UPDATE_MONNEYINDEX, UPDATE_MONNEYITEM,UPDATE_SETTINGINFO,UPDATE_RUNAD, UPDATE_LOGINSTATUS,UPDATE_ADVERTINFO, UPDATE_VIPLIST, UPDATE_VIPINDEX, UPDATE_VIPITEM,UPDATE_NAVIGATORLISTINFO} from './actionTypes';

const updateServerTime = (data) => {
  return {
    type: UPDATE_SERVIERTIME,
    ServerTime: data,
  };
};

const updateVideo = (data) => {
  return {
    type: UPDATE_VIDEO,
    VideoInfo: data,
  };
};

const updateToken = (data) => {
  return {
    type: UPDATE_TOKEN,
    token: data,
  };
};
const updateUser = (userdata) => {
  return {
    type: UPDATE_USER,
    userdata: userdata,
  };
};
const updateLoginStatus = (data) => {
  return {
    type: UPDATE_LOGINSTATUS,
    loginStatus: data,
  };
};


const updateNotific = (data) => {
  return {
    type: UPDATE_NOTIFICATION,
    notificdata: data,
  };
};

const updateSearchInfo = (data) => {
  return {
    type: UPDATE_SEARCHINFO,
    searchinfo: data,
  };
};
const updateSearch = (data) => {
  return {
    type: UPDATE_SEARCH,
    search: data,
  };
};
const updateSearchClick = (data) => {
  return {
    type: UPDATE_SEARCH_CLICk,
    searchclick: data,
  };
};

const updatePayList = (data) => {
  return {
    type: UPDATE_PAYLIST,
    paylist: data,
  };
};
const updateMonneyList = (data) => {
  return {
    type: UPDATE_MONNEYLIST,
    monneylist: data,
  };
};

const updateMonneyIndex = (data) => {
  return {
    type: UPDATE_MONNEYINDEX,
    monneyindex: data,
  };
};

const updateMonneyItem = (data) => {
  return {
    type: UPDATE_MONNEYITEM,
    monneyitem: data,
  };
};

const updateVipList = (data) => {
  return {
    type: UPDATE_VIPLIST,
    viplist: data,
  };
};

const updateVipIndex = (data) => {
  return {
    type: UPDATE_VIPINDEX,
    vipindex: data,
  };
};

const updateVipItem = (data) => {
  return {
    type: UPDATE_VIPITEM,
    vipitem: data,
  };
};

const updateSettingInfo = (data) => {
  return {
    type: UPDATE_SETTINGINFO,
    settingInfo: data,
  };
};

const updateRunAd = (data) => {
  return {
    type: UPDATE_RUNAD,
    RunAd: data,
  };
};

const updateAdvertInfo = (data,adType) => {
  return {
    type: UPDATE_ADVERTINFO,
    data: data,
    adType:adType
  };
};

const updateNavigatorListInfo = (data) => {
  return {
    type: UPDATE_NAVIGATORLISTINFO,
    NavigatorListInfo: data,
  };
};


export {updateServerTime,updateVideo,updateToken,updateUser,updateLoginStatus,updateNotific,updateSearchInfo,updateSearch,updateSearchClick,updatePayList,updateMonneyList,updateMonneyIndex,updateMonneyItem,updateVipList,updateVipIndex,updateVipItem,updateSettingInfo,updateRunAd,updateAdvertInfo,updateNavigatorListInfo};
