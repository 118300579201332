import request from '../Utils/request';
import EncryptAPI from '../Utils/EncryptAPI';
import CryptoJS from '../Utils/CryptoJS';
export const ajaxService = () => {
  Promise.resolve('1213');
};

export const fetchGetTagByArea = params => {
  return request.post('/Base_Manage/TagVideo/GetTagByArea',params);
}
export const API_User_GetServerTime = params => {
  return EncryptAPI.post('/User/GetServerTime',params);
}

//新-----------------------
/**
 * 用户-登录接口
 */
export const API_Get_OssMian = params => {
  return EncryptAPI.get(params);
}

export const API_User_Login = params => {
  return EncryptAPI.post('/User/Login',params);
}
export const API_User_Bind = params => {
  return EncryptAPI.post('/User/Bind',params);
}



/**
 * 用户-登录接口
 */
 export const API_User_GetUserData = params => {
  return EncryptAPI.post('/User/GetUserData',params);
}

/**
 * 视频-推荐列表
 */
export const API_Video_Main = () => {
  return EncryptAPI.post('/Video/Main');
}
export const API_Video_GetMain = params => {
  return EncryptAPI.post('/Video/GetMain',params);
}

/**
 * 视频-最新列表
 */
export const API_Video_New = params => {
  return EncryptAPI.post('/Video/GetNewVideo',params);
}

/**
 * 视频-金币区
 */
 export const API_Video_Monney = params => {
  return EncryptAPI.post('/Video/Monney',params);
}

/**
 * 视频-区域推荐列表
 */
 export const API_Video_GetAreaVideo = params => {
  return EncryptAPI.post('/Video/GetAreaVideo',params);
}

export const API_Video_Tag = params => {
  return EncryptAPI.post('/Video/Tag',params);
}

/**
 * 视频-区域推荐列表
 */
 export const API_Video_AllTag = params => {
  return EncryptAPI.post('/Video/AllTag',params);
}

/**
 * 视频-区域列表
 */
export const API_Video_List = params => {
  return EncryptAPI.post('/Video/List',params);
}

/**
 * 视频-区域列表
 */
 export const API_Video_GetRandList = params => {
  return EncryptAPI.post('/Video/GetRandList',params);
}

/**
 * 视频-搜索
 */
 export const API_Video_Search = params => {
  return EncryptAPI.post('/Video/Search',params);
}


/**
 * 短视频-随机推荐
 */
 export const API_ShortVideo_GetDataListByNext = params => {
  return EncryptAPI.post('/ShortVideo/GetDataListByNext',params);
}

/**
 * 短视频-首页推荐
 */
 export const API_ShortVideo_GetMainList = params => {
  return EncryptAPI.post('/ShortVideo/GetMainList',params);
}

/**
 * 短视频-搜索
 */
 export const API_ShortVideo_Search = params => {
  return EncryptAPI.post('/ShortVideo/Search',params);
}

/**
 * 短视频-新片区
 */
 export const API_ShortVideo_GetNewList = params => {
  return EncryptAPI.post('/ShortVideo/GetNewList',params);
}

/**
 * 短视频-金币区
 */
 export const API_ShortVideo_GetMoneyList = params => {
  return EncryptAPI.post('/ShortVideo/GetMoneyList',params);
}

/**
 * 短视频-列表
 */
 export const API_ShortVideo_GetList = params => {
  return EncryptAPI.post('/ShortVideo/GetList',params);
}

/**
 * 漫画-首页推荐
 */
 export const API_Cartoon_Main = params => {
  return EncryptAPI.post('/Cartoon/Main',params);
}
/**
 * 漫画-搜索
 */
 export const API_Cartoon_Search = params => {
  return EncryptAPI.post('/Cartoon/Search',params);
}

/**
 * 漫画-列表
 */
 export const API_Cartoon_List = params => {
  return EncryptAPI.post('/Cartoon/List',params);
}

/**
 * 漫画-信息列表
 */
 export const API_Cartoon_Info = params => {
  return EncryptAPI.post('/Cartoon/Info',params);
}


/**
 * 小说-首页推荐
 */
 export const API_XiaoShuo_Main = params => {
  return EncryptAPI.post('/XiaoShuo/Main',params);
}
/**
 * 小说-列表
 */
 export const API_XiaoShuo_List = params => {
  return EncryptAPI.post('/XiaoShuo/List',params);
}
/**
 * 小说-信息
 */
 export const API_XiaoShuo_Info = params => {
  return EncryptAPI.post('/XiaoShuo/Info',params);
}
/**
 * 小说-搜索
 */
 export const API_XiaoShuo_Search = params => {
  return EncryptAPI.post('/XiaoShuo/Search',params);
}


/**
 * 支付-vip
 */
 export const API_Pay_VIP = params => {
  return EncryptAPI.post('/Pay/VIP',params);
}

/**
 * 支付-金币
 */
 export const API_Pay_Monney = params => {
  return EncryptAPI.post('/Pay/Monney',params);
}


/**
 * 购买-消费明细
 */
 export const API_Buy_Info = params => {
  return EncryptAPI.post('/Buy/Info',params);
}

/**
 * 购买-确认购买
 */
 export const API_Buy_BuyOK = params => {
  return EncryptAPI.post('/Buy/BuyOK',params);
}

/**
 * 支付-确认
 */
export const API_Pay = params => {
  return EncryptAPI.post('/Pay_Manage/Pay/Pay',params);
}