/*
 * 环境配置
 */

export const ServerList = [
  {Client:
    {
      Server:[
        "http://jljoip.com/server.json",
        "http://jljoip112233.com/server.json",//备用
      ],
    },
    夜艳:
    {
      Server:[
        "https://yyyy-app-bak.oss-accelerate.aliyuncs.com/server.json",
        "https://yyyy-app1.oss-accelerate.aliyuncs.com/server.json",
        "http://jljoipyyyy.com/server.json",
        "http://jljoip1122332.com/server.json",//备用
      ],
    }
  },

]
