import { registerRootComponent } from 'expo';
import App from './App';
import {isWeb} from './src/Utils/Index'
import * as Device from 'expo-device';
//import {CacheManager} from './src/Components/Image-Cache'
//CacheManager.clearCache()
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
//import inobounce from './src/Utils/inobounce'
//inobounce.enable()
//console.log(Device.osName )
if(isWeb && Device.osName =='iOS'){
    const inobounce = require('./src/Utils/inobounce')
    inobounce.enable()
}
registerRootComponent(App);
