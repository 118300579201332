import {UPDATE_USER, UPDATE_LOGINSTATUS,UPDATE_NOTIFICATION, UPDATE_SEARCHINFO,UPDATE_SEARCH,UPDATE_SEARCH_CLICk,UPDATE_LANGUAGE, UPDATE_CART_TEIMS, UPDATE_MONNEYLIST, UPDATE_PAYLIST, UPDATE_MONNEYINDEX,UPDATE_MONNEYITEM, UPDATE_SETTINGINFO,UPDATE_RUNAD,UPDATE_ADVERTINFO, UPDATE_VIPLIST, UPDATE_VIPINDEX, UPDATE_VIPITEM, UPDATE_VIDEO, UPDATE_NAVIGATORLISTINFO, UPDATE_SERVIERTIME} from '../../Actions/actionTypes';

const initialState = {
  ServerTime:{},
  userdata: {},
  loginStatus: -1,    // -1 == loading, 0 = not logged in, 1 == logged in
  notificdata:[],
  searchInfo:[],
  search:{},          //搜索内容
  searchclick:{},
  paylist:[],

  monneylist:[],
  monneyindex:0,      //金额,默认选中项
  monneyitem:{},      //选中数据!给确认支付显示

  viplist:[],
  vipindex:0,         //金额,默认选中项
  vipitem:{},         //选中数据!给确认支付显示

  settingInfo:{},     //设置
  //--------------------------------------------
  RunAd:{Data:[]},            //启动页广告
  HomeAd:{},                  //首页
  HomeAd2:{},                  //首页2
  GuoChanAd:{},               //国产
  RiHanAd:{},                 //日韩
  OuMeiAd:{},                 //欧美
  VideoListAd:{},             //视频列表
  PlayerAd:{},                //播放器
  ShortVideoAd:{},            //短视频
  ManHuaAd:{},                //漫画
  XiaoShuoAd:{},              //小说
  YuanChuangAd:{},            //原创
  ZhuanTiAd:{},               //专题
  MonneyAd:{},                //金币
  MonneyListAd:{},            //金币列表
  ShortVideoJingXuanAd:{},    //短视频
  //--------------------------------------------

  VideoInfo:{},               //视频信息

  NavigatorListInfo:{},       //导航信息


  
};
const updateDecryptKey = (state, action) => {
  let ud = action.DecryptKey;
  return {...state, DecryptKey: ud};
};

const updateServerTime = (state, action) => {
  //console.log(action)
  let ud = action.ServerTime;
  return {...state, ServerTime: ud};
};

const updateVideo = (state, action) => {
  //console.log(action)
  let ud = action.VideoInfo;
  return {...state, VideoInfo: Object.assign({}, ud)};
};

const updateUser = (state, action) => {
  let ud = action.userdata;
  return {...state, userdata: Object.assign({}, ud)};
  //return {...state, userdata: Object.assign({}, ud), loginStatus: ud ? 1 : 0};
};
const updateLoginStatus = (state, action) => {
  let ud = action.loginStatus;
  //alert(ud)
  return {...state, loginStatus: ud};
};


const updateNotific = (state, action) => {
  //alert(123)
  let ud = action.notificdata;
  return {...state, notificdata: Object.assign([], ud)};
};

const updateSearchInfo = (state, action) => {
  //alert(123)
  //console.log(action)
  let ud = action.searchinfo;
  return {...state, searchInfo: Object.assign([], ud)};
};

const updateSearch = (state, action) => {
  //alert(123)
  //console.log(action)
  let ud = action.search;
  return {...state, search: Object.assign({}, ud)};
};

const updateSearchClick = (state, action) => {
  //alert(123)
  //console.log(action)
  let ud = action.searchclick;
  return {...state, searchclick: Object.assign({}, ud)};
};

const updatePayList = (state, action) => {
  //alert(123)
  let ud = action.paylist;
  return {...state, paylist: Object.assign([], ud)};
};

const updateMonneyList = (state, action) => {
  let ud = action.monneylist;
  return {...state, monneylist: Object.assign([], ud)};
};

const updateMonneyIndex = (state, action) => {
  let ud = action.monneyindex;
  return {...state, monneyindex:ud};
};

const updateMonneyItem = (state, action) => {
  let ud = action.monneyitem;
  return {...state, monneyitem: Object.assign({}, ud)};
};

const updateVipList = (state, action) => {
  let ud = action.viplist;
  return {...state, viplist: Object.assign([], ud)};
};

const updateVipIndex = (state, action) => {
  let ud = action.vipindex;
  return {...state, vipindex:ud};
};

const updateVipItem = (state, action) => {
  let ud = action.vipitem;
  return {...state, vipitem: Object.assign({}, ud)};
};


const updateSettingInfo = (state, action) => {
  let ud = action.settingInfo;
  return {...state, settingInfo: Object.assign({}, ud)};
};

const updateRunAd = (state, action) => {
  let ud = action.RunAd;
  return {...state, RunAd: Object.assign({}, ud)};
};

const updateAdvertInfo = (state, action) => {
  let ud = action.data;
  //console.log(action)
  switch (action.adType) {
    case 1:
      return {...state, RunAd: Object.assign({}, ud)};
    case 2:
      return {...state, HomeAd: Object.assign({}, ud)};
    case 3:
      return {...state, GuoChanAd: Object.assign({}, ud)};
    case 4:
      return {...state, RiHanAd: Object.assign({}, ud)};
    case 5:
      return {...state, OuMeiAd: Object.assign({}, ud)};
    case 6:
      return {...state, VideoListAd: Object.assign({}, ud)};
    case 7:
      return {...state, PlayerAd: Object.assign({}, ud)};
    case 8:
      return {...state, ShortVideoAd: Object.assign({}, ud)};  
    case 9:
      return {...state, ManHuaAd: Object.assign({}, ud)};  
    case 10:
      return {...state, XiaoShuoAd: Object.assign({}, ud)};  
    case 11:
      return {...state, YuanChuangAd: Object.assign({}, ud)};  
    case 12:
      return {...state, ZhuanTiAd: Object.assign({}, ud)};  
    case 13:
      return {...state, MonneyAd: Object.assign({}, ud)};  
    case 14:
      return {...state, MonneyListAd: Object.assign({}, ud)};  
    case 15:
      return {...state, ShortVideoJingXuanAd: Object.assign({}, ud)};  
    case 22:
      return {...state, HomeAd2: Object.assign({}, ud)};
    default:
  }

  
  
  return {...state,
    RunAd: Object.assign({}, ud),
    HomeAd: Object.assign({}, ud),
    HomeAd2: Object.assign({}, ud),
    GuoChanAd: Object.assign({}, ud),
    RiHanAd: Object.assign({}, ud),
    OuMeiAd: Object.assign({}, ud),
    VideoListAd: Object.assign({}, ud),
    ShortVideoAd: Object.assign({}, ud),
    ManHuaAd: Object.assign({}, ud),
    XiaoShuoAd: Object.assign({}, ud),
    YuanChuangAd: Object.assign({}, ud),
    ZhuanTiAd: Object.assign({}, ud),
    MonneyAd: Object.assign({}, ud),
    MonneyListAd: Object.assign({}, ud),
    ShortVideoJingXuanAd: Object.assign({}, ud)
  };
};
const updateNavigatorListInfo = (state, action) => {
  let ud = action.NavigatorListInfo;
  return {...state, NavigatorListInfo: Object.assign([], ud)};
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SERVIERTIME:
      return updateServerTime(state, action);
    case UPDATE_USER:
      return updateUser(state, action);
    case UPDATE_NOTIFICATION:
      return updateNotific(state, action);  
    case UPDATE_PAYLIST:
      return updatePayList(state, action);  
    case UPDATE_MONNEYLIST:
      return updateMonneyList(state, action);  
    case UPDATE_MONNEYINDEX:
      return updateMonneyIndex(state, action);  
    case UPDATE_MONNEYITEM:
      return updateMonneyItem(state, action);  
    case UPDATE_VIPLIST:
      return updateVipList(state, action);  
    case UPDATE_VIPINDEX:
      return updateVipIndex(state, action);  
    case UPDATE_VIPITEM:
      return updateVipItem(state, action); 
    case UPDATE_SEARCHINFO:
      return updateSearchInfo(state, action);  
    case UPDATE_SEARCH:
      return updateSearch(state, action);  
    case UPDATE_SEARCH_CLICk:
      return updateSearchClick(state, action); 
    case UPDATE_SETTINGINFO:
      return updateSettingInfo(state, action); 
    case UPDATE_RUNAD:
        return updateRunAd(state, action); 
    case UPDATE_LOGINSTATUS:
      return updateLoginStatus(state, action); 
    case UPDATE_ADVERTINFO:
      return updateAdvertInfo(state, action); 
    case UPDATE_VIDEO:
      return updateVideo(state, action); 
    case UPDATE_NAVIGATORLISTINFO:
      return updateNavigatorListInfo(state, action); 
    default:
      return state;
  }
};

export default userReducer;
