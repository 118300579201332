import "react-native-gesture-handler";

import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { NativeBaseProvider } from 'native-base';
//import { useLoadedAssets } from "./hooks/useLoadedAssets";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { useColorScheme } from "react-native";
import {Provider} from 'react-redux';
import {store} from './src/Store/index';
import { StoreProvider } from './src/Utils/store';


import { BaseTheme } from './src/theme';
//import config from './nativebase.config';
import Navigation from "./navigation/index";

export default function App() {
  //const isLoadingComplete = useLoadedAssets();
  const colorScheme = useColorScheme();

 

  return (
    <GestureHandlerRootView style={{flex:1}}>
      <SafeAreaProvider>
        <NativeBaseProvider theme={BaseTheme}>
        <Provider store={store}>
          <StoreProvider>
                <Navigation colorScheme={colorScheme} />
          </StoreProvider>
        </Provider>
        {/* <StatusBar  /> */}
        <StatusBar backgroundColor="transparent" translucent={true} />
      </NativeBaseProvider>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );

}