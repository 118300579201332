// If you are not familiar with React Navigation, check out the "Fundamentals" guide:
// https://reactnavigation.org/docs/getting-started
import {
    DarkTheme,
    DefaultTheme,
    NavigationContainer,
  } from "@react-navigation/native";
  import { createStackNavigator } from "@react-navigation/stack";
  import BottomTabNavigator from "./BottomTabNavigator";
  import {useSelector} from 'react-redux';

  import Login from './Login';
  import AdScreen from './AdScreen';
  import Player from "../src/View/Main/Player/index";
  import DeviceInfo from '../src/View/Me/DeviceInfo';
  import WebView from '../src/Components/WebView';
  import WebBrowser from '../src/Components/WebBrowser';
  
  import History from '../src/View/Me/HistoryList/Index';
  import Fllow from '../src/View/Me/FllowList/Index';
  import PayHistory from '../src/View/Me/PayHistoryList/Index';
  import Task from '../src/View/Task/Index';
  import Notice from '../src/Components/Notice';

  import Pay from '../src/View/newPay/Index';
  import VideoList from '../src/View/Main/PlayerList/VideoList';
  import Search from '../src/View/Main/Search-New/Index';


  import ServerTime from './ServerTime';  

  export default function Navigation({ colorScheme }) {
    const loginStatus = useSelector((state) => state.user.loginStatus);
    //console.log(loginStatus)
    if(loginStatus<=0){
        return <Login/>
    }else if(loginStatus==2){
        return <AdScreen/>
    }

    const PostMessage = (Msg) =>{
      if(window.ReactNativeWebView){
        window.ReactNativeWebView.postMessage(JSON.stringify({route:Msg}));
      }
    }

    const Stack = createStackNavigator();
    return (
      <NavigationContainer>
        <Stack.Navigator 
        screenListeners={{
          state: (e) => {
            // Do something with the state
            // console.log('state changed', e.data);
            // const routesLength = e.data.state.routes[e.data.state.routes.length-1]
            // console.log(routesLength)
          },
        }}
        screenOptions={{ 
        }}>
        <Stack.Screen 
            listeners={({ navigation, route }) => ({
              focus: () =>  PostMessage("首页"),
            })}
            name="Root" component={BottomTabNavigator} options={{
            headerShown: false 
          }}/>
          <Stack.Screen name="Player" 
            listeners={({ navigation, route }) => ({
              focus: () =>  PostMessage("播放器"),
            })}
            component={Player} options={{
            headerShown: false 
          }} />
          <Stack.Screen name="Task" component={Task} options={{ 
            title: '邀请好友',
            headerBackTitleVisible: false,
            headerTitleAlign:'center'
          }} />
          <Stack.Screen name="DeviceInfo" component={DeviceInfo} options={{ 
            title: '设备信息',
            headerTitleAlign:'center',
            headerBackTitleVisible: false }} />
          <Stack.Screen name="WebView" component={WebView} options={{ 
            title: '浏览器',
            headerTitleAlign:'center',
            headerBackTitleVisible: false }} />
          <Stack.Screen 
            listeners={({ navigation, route }) => ({
              focus: () =>  PostMessage("视频列表"),
            })}
            name="History" component={History} options={{ 
            title: '观看历史',
            headerTitleAlign:'center',
            headerBackTitleVisible: false }} />
          <Stack.Screen 
            listeners={({ navigation, route }) => ({
              focus: () =>  PostMessage("视频列表"),
            })}
            name="Fllow" component={Fllow} options={{ 
            title: '我的收藏',
            headerTitleAlign:'center',
            headerBackTitleVisible: false }} />
          <Stack.Screen 
            listeners={({ navigation, route }) => ({
              focus: () =>  PostMessage("视频列表"),
            })}
            name="PayHistory" component={PayHistory} options={{ 
            title: '我的购买',
            headerTitleAlign:'center',
            headerBackTitleVisible: false 
            }} />
          <Stack.Screen 
            listeners={({ navigation, route }) => ({
              focus: () =>  PostMessage("充值"),
            })}
            name="Pay" component={Pay} options={{ 
            title: '充值',
            headerTitleAlign:'center',
            headerBackTitleVisible: false
          }} />
          <Stack.Screen name="VideoList" component={VideoList} 
            listeners={({ navigation, route }) => ({
              focus: () =>  PostMessage("视频列表"),
            })}
            options={({ route }) => ({ 
              headerBackTitleVisible: false,
              headerTitleAlign:'center',
              headerTitle: route.params.Data.VideoTag,
          })}/>
          <Stack.Screen
            listeners={({ navigation, route }) => ({
              focus: () =>  PostMessage("搜索"),
            })} 
            name="Search" component={Search} 
            options={({ route }) => ({ 
              headerShown: false 
          })}/>
          <Stack.Screen name="WebBrowser" component={WebBrowser} 
            options={({ route }) => ({ 
              //headerShown: false 
          })}/>
          
      </Stack.Navigator>
      <Notice/>
      <ServerTime/>
      </NavigationContainer>
    );
}
  
