import React, {useEffect,useState,useContext} from 'react';
import axios from 'axios';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';
import {StatusBar,Platform,Linking,ImageBackground,StyleSheet, View, Text, TouchableOpacity, SafeAreaView} from 'react-native';
//Redux
import {Image} from 'expo-image'
import {useDispatch,useSelector} from 'react-redux';
import {updateServerTime,updateToken, updateNotific,updateSearchInfo ,updateUser,updateSettingInfo,updateRunAd, updateLoginStatus,updateAdvertInfo,updateNavigatorListInfo} from '../src/Actions/userActions';
import { isAndRoid,isWeb,isIOS,GetUID,SetUID,GetUniqueId} from '../src/Utils/Index';
//api
import {API_User_Login,API_Get_OssMian} from '../src/API/Index'
import request from '../src/Utils/request';
//token
import { setToken,setUser,setApiBase } from '../src/Utils/storage';
import { ServerList } from '../src/Utils/Config';

//import { AppName, version } from "../package.json";
import * as Device from 'expo-device';
import * as Clipboard from 'expo-clipboard';
//import * as Updates from 'expo-updates';
//import { AndroidConfig } from 'expo/config-plugins';
import { ExpoConfig } from 'expo/config';
import Constants from 'expo-constants';
import { AntDesign,MaterialIcons } from '@expo/vector-icons';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import DeviceInfo from 'react-native-device-info';
import CryptoJS from '../src/Utils/CryptoJS';
//import Clipboard from '@react-native-clipboard/clipboard';

export default function Login() {
  const [SplashImage,setSplashImage] = useState(require('../assets/images/splash.png'))
  const dispatch = useDispatch();
  const { getItem, setItem } = useAsyncStorage('@storage_settingInfos');

  const Config:any = Constants;
  const [copiedText, setCopiedText] = React.useState('');
  const [modalVisible,setmodalVisible] = useState(false)
  const [LoadingState,setLoadingState] = useState(1)

  const [State,SetState] = useState(0)
  const [Index,setIndex] = useState(0)
  const [ErrorCount,setErrorCount] = useState(0)
  const [CustomerServiceUrl,setCustomerServiceUrl]= useState(null)
  const [Android_UUID,SetAndroid_UUID] = useState(null)

  const [Message,SetMessage] = useState("1")
  //const RunAd = useSelector((state) => state.user.RunAd);

  const GetCustomerServiceUrl= async()=>{
    const ret:any = await getItem()
    const settingInfo:any = JSON.parse(ret);
    if(settingInfo && settingInfo.CustomerServiceUrl){
      setCustomerServiceUrl(settingInfo.CustomerServiceUrl)
    }
  }

  const GetClipboard = async () =>{
    try {
      return await Clipboard.getStringAsync();
    } catch (error) {
      return ""
    }
  }

  const doBind = async()=>{
    try {
      //return await Clipboard.getStringAsync();
    } catch (error) {
      return ""
    }
    return ""
  }

  const doLogin = async () =>{
    try {
      if(!CustomerServiceUrl){
        GetCustomerServiceUrl()
      }  

      //console.log('进入服务器')
 
      let BindType=''
      let extensionCode=''
      let text:any = await GetClipboard();; 
      //let text = await GetClipboard();
      //console.log('text',text)
      //console.log('text',navigator)
      
      let parts = text.split("=")
      if(parts.length ==2){
        if(parts[0]=="a" || parts[0]=="u"||parts[0]=="bind"){
          BindType=parts[0]
          extensionCode= parts[1]
        }
       
      }

      let uid = await GetUniqueId() 
      if(uid == null || uid == "unknown"){
        uid = await GetUID() //Crypto.randomUUID();
      }
      if(Android_UUID){
        uid = Android_UUID
      }
 
      console.log(uid)
      if(BindType == "bind" && extensionCode != ""){
        uid = extensionCode;
      }

      if(Config.expoConfig.extra.AgentId != "0"){
        BindType="a"
        extensionCode=Config.expoConfig.extra.AgentId
      }
      //console.log(uid)
      const data:any =  await API_User_Login({
        mechineCode:uid,//DeviceInfo.getUniqueId(),
        osName:Device.osName,
        platform: Platform.OS,
        BindType:BindType,
        extensionCode:extensionCode
        })
        //console.log(data)
        const NavigatorList = []
        data.NavigatorList.forEach(element => {
          //console.log(element.Area,element.Area != '专题')
          if(element.Area != '专题'){
            element.key = element.Area
            element.title = element.Area
            NavigatorList.push(element)
          }
          //console.log(element)
        })
        var User = JSON.stringify(data.userInfo);
        setUser(User)
        SetUID(data.userInfo.MechineCode)
        setToken(data.Token)
        updateToken(data.Token)
        //console.log()
        dispatch(updateServerTime(data.ServerTime.toString()))//服务器时间
        
        dispatch(updateUser(data.userInfo));
        dispatch(updateSearchInfo(data.searchInfos));  //搜索列表
        dispatch(updateSettingInfo(data.settingInfos));//后台设置
        setItem( JSON.stringify(data.settingInfos))
        dispatch(updateNotific(data.noticeInfos))
        dispatch(updateNavigatorListInfo(NavigatorList))

        let JumpRunAd = true;
        data.advertInfos.forEach(element => {
          switch (element.Title) {
            case '启动页':
              dispatch(updateAdvertInfo(element,1))
              JumpRunAd = false
              break;
            case '首页':
              dispatch(updateAdvertInfo(element,2))
              break;
            case '首页-横幅':
              dispatch(updateAdvertInfo(element,22))
              break;
            case '播放器':
              dispatch(updateAdvertInfo(element,7))
              break;
            case '短视频':
              dispatch(updateAdvertInfo(element,8))
              break;
            case '专题':
              dispatch(updateAdvertInfo(element,12))
              break;
          } 
        }); 

        setErrorCount(0)
        SetState(999)

        if(JumpRunAd){//跳过启动页广告
          dispatch(updateLoginStatus(1));
        }else{
          dispatch(updateLoginStatus(2));
        }
        
        return
      
    } catch (error) {
        console.log("error:",error)
        setErrorCount(ErrorCount+1)
        setIndex(0)
        SetState(0)
    }
  }

  const syncSleep =(time) =>{
    const start = new Date().getTime();
    while (new Date().getTime() - start < time) {}
  }

  //解密方法
  const Decrypt = (word) => {
    var key = CryptoJS.enc.Utf8.parse('12345678900000001234567890000000'); 
    var iv = CryptoJS.enc.Utf8.parse('1234567890000000');
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  }

  const GetServerUrl = async () =>{
    if(!CustomerServiceUrl){
      GetCustomerServiceUrl()
    }

    const List = GetServerList()
    for (const key in List) {
        if (Object.prototype.hasOwnProperty.call(List, key)) {
            const Url = List[key];
            try {
                //const Ret:any = await request.get(Url)
                const Ret:any = await axios.get(Url)
                const Data = JSON.parse(Decrypt(Ret.data.Data))
                //const Ret:any = await API_Get_OssMian(Url)
                console.log("Oss:",Data)
                if(Data){
                    
                    setApiBase(Data.Server)
                    SetState(1)
                    return
                }
               
            } catch (error) {
              //console.log("error",error)
              //console.log("GetServerList:",error,ErrorCount)
              syncSleep(1000)
              setErrorCount(ErrorCount+1)
              
              //SetState(2)
              //return
            }
        }
        setIndex(Index+1)
    }
    setTimeout(() => {
        setIndex(0)
        GetServerUrl()
    },3000);
  }

  const GetServerList =()=>{
    var list = []
    //console.log(AppName)
    //console.log('Constants:,',Constants.expoConfig.extr.AppName)
    const appname = Config.expoConfig.extra.AppName
    //console.log(appname)
    ServerList.forEach(i => {
      if(i[appname]){
        list = i[appname].Server
      }
    })
    return list
  }

  // React.useEffect(() => {
  //   function handleEvent(message) {
  //     var sTr:any = JSON.stringify(message.data);
  //     //alert(sTr)
  //     var data:any = message.data;
  //     if(data.Android_UUID){
  //       //alert(data.Android_UUID)
  //       SetAndroid_UUID(data.Android_UUID)
  //     }
  //   }
  //   document.addEventListener("message", handleEvent);
  //   //console.log(window.ReactNativeWebView)
  //   if(window.ReactNativeWebView){
  //     window.ReactNativeWebView.postMessage(JSON.stringify({Login:true}));
  //   }
   

  //   return () =>
  //     document.removeEventListener("message", handleEvent);
  // }, []);

  
  useEffect(() => {
    if(ErrorCount>=3){
      SetState(2)
    }else{
      if(State ==0){
        GetServerUrl()
      }else if (State==1){
        doLogin()
      }
    }
   
  },[State])

  const onPressCustomerServiceUrl = async () =>{
    if(CustomerServiceUrl){
      Linking.openURL(CustomerServiceUrl)
    }
  }

  const RestApp =()=>{
    return(
      <View style={{alignItems:'center'}}>
        <MaterialIcons name="error-outline" size={28} color="#fff" />
        <Text style={{marginTop:10,fontWeight:'bold',color:'#fff'}} >暂无可用线路</Text>
        <Text style={{marginTop:10,fontWeight:'bold',color:'#fff'}} >官方地址：</Text>
        <Text style={{marginTop:0,fontWeight:'bold',color:'#fff'}} >yd111.app ~ yd333.app</Text>
        {
          CustomerServiceUrl ?
            <Button onPress={()=>
              onPressCustomerServiceUrl()
            }  style={{marginTop:16}} size="sm">联系客服</Button>
          : null

        }
        
      </View>
    )
  }

  return (
    <View style={{flex:1,
    //backgroundColor:'#ff7500'
    }}>
      <Image
            recyclingKey={"RunImage"}
              style={{
                flex:1,
                width: null as any,
                height: null as any,
                //backgroundColor: '#eee',
                //borderRadius:8
              }}
              source={SplashImage}
              contentFit='cover'
          />
  <View style={{position:'absolute',left:0,right:0,top:0,bottom:0,justifyContent:'center',alignItems:'center',}}>
        <View style={{backgroundColor:'rgba(0,0,0,0.6)',padding:22,borderRadius:8,}}>

         
          {
       
              State == 0 ?
              <>
               <Spinner style={{marginBottom:12}} size="lg" color="#fff" />
               <Text style={{fontWeight:'bold',color:'#fff'}} >正在连接服务器:{Index}</Text>
              </>
             
              : State == 1 ?
              <>
                <Spinner style={{marginBottom:12}} size="lg" color="#fff" />
                <Text style={{fontWeight:'bold',color:'#fff'}} >正在获取配置中...</Text>
              </>
            
              : RestApp()
        
          }
          {/* <Text>1{Android_UUID}</Text> */}
          </View>
    </View>
  </View>

   
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});


