import AsyncStorage from '@react-native-async-storage/async-storage';

const TOKEN_CONSTANT = 'dingdong_user_token';
const Api_Base = 'Api_Base';

class Storage {
  /**
   * 获取
   */

  static get = async (key) => {
      try {
          const value = await AsyncStorage.getItem(key);
          //console.log(value)
          if (value !== null) {
              // We have data!!
              return JSON.parse(value)
          } else {
              return false
          }
      } catch (error) {
          return false
      }
  }


  /**
   * 保存
   */
  static save = async (key, value) => {
      try {
          await AsyncStorage.setItem(key, JSON.stringify(value));
          return true
      } catch (error) {
          // Error saving data
          return false
      }
  }


  /**
   * 更新
   */
  static update = async (key, value) => {
      try {
          await AsyncStorage.mergeItem(key, JSON.stringify(value));
          return true
      } catch (error) {
          // Error saving data
          return false
      }
  }


  /**
   * 删除
   */
  static delete = async (key) => {
      try {
          await AsyncStorage.removeItem(key);
          return true
      } catch (error) {
          // Error saving data
          return false
      }
  }
}

export default Storage;
/**
 * 获取token
 */
export const getToken = () => AsyncStorage.getItem(TOKEN_CONSTANT);

/**
 * 设置token
 * @param value token
 */
export const setToken = (value) => {
  AsyncStorage.setItem(TOKEN_CONSTANT, value);
};

/**
 * 设置ApiBase
 * @param value token
 */
export const setApiBase = (value) => {
    AsyncStorage.setItem(Api_Base, value);
};

/**
 * 获取ApiBase
 */
 export const getApiBase = () => AsyncStorage.getItem(Api_Base);

/**
 * 清除本地存储数据
 */
export const clearStorage = () => {
  AsyncStorage.clear();
};


export const getUser = () =>  AsyncStorage.getItem('data');
export const setUser = (data) =>  AsyncStorage.setItem('data',data);
export const getNoticeInfos = () => AsyncStorage.getItem('noticeInfos');


